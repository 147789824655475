import React, { useEffect, useState } from "react";
import { useCallback } from 'react';
import MainUser from '../../components/Users/MainUser';
import { register, remove, search, update } from '../../services/auth';


export default function Users({ setTitle }) {
  const token = window.sessionStorage.getItem('USER_TOKEN');
  useEffect(() => { setTitle('HandiCraft - Users'); }, [setTitle]);

  const [optionSearch, setOptionSearch] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
  });
  const [data, setData] = useState();

  // Get data
  
  const searchData = useCallback((option) => {
    search(option, token).then((res) => setData(res)).catch((err) => console.log(err.message));
  }, [token]);

  useEffect(() => {
    searchData(optionSearch);
  }, [optionSearch, searchData]);

  const handlePost = (option, action) => {
    if (option)
    {
      switch (action) {
        case 0:
          return register(option);
        case 1:
          return update(option, token);
        case 2:
          return remove(option, token);
        default:
          break;
      }
    }
  }

  return (
    <div className='min-h-screen p-4'>
      <MainUser data={data} optionSearch={optionSearch} searchData={searchData} handlePost={handlePost} setOptionSearch={setOptionSearch} setData={setData} />
    </div>
  );
}
