import instance from './axios';

export const search = async (data) => (await instance.post('/update/search', {
  ...data
})).data;

export const getById = async (id) => (await instance.get('/update/getById/' + id)).data;

export const getByPath = async (id) => (await instance.get('/update/getByPath/' + id)).data;

export const remove = async (data) => (await instance.post('/update/delete', {
  ...data
}));

export const update = async (data) => (await instance.post('/update/update', {
  ...data
}));

export const create = async (data) => (await instance.post('/update/create', {
  ...data
}));
