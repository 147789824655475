import { useEffect, useRef, useState } from 'react';
import { convertToPath } from '../../utils/formatString';
import { modalUpdateValidator } from '../../utils/validation';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { uploadMulti } from '../../services/file';
import Loading from '../Shared/Loading/Loading';
import { toast } from 'react-toastify';

let listImages = [],
  listImagesIndex = [];
export default function Modal({
  titleModal,
  itemSelected,
  showModal,
  setShowModal,
  handlePost,
  data,
  setData,
}) {
  const [isLoading, setIsLoading] = useState(false);

  // const titleUpdateRef = useRef();
  const pathRef = useRef();
  const orderRef = useRef();

  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState();
  const [titleUpdate, setTitleUpdate] = useState('');

  // const [listImages, setListImages] = useState([]);

  useEffect(() => {
    if (itemSelected && pathRef) {
      setTitleUpdate(itemSelected.title);
      pathRef.current.value = itemSelected.path;
      orderRef.current.value = itemSelected.order || 0;
      setContent(itemSelected.content);
    } else emptyValues();
  }, [itemSelected]);

  const emptyValues = () => {
    if (pathRef) {
      setTitleUpdate('');
      setContent('');
      setTitleUpdate('');
      orderRef.current.value = 0;
    }
  };

  const handleUploadImages = async (files) => {
    let listPaths = [];
    if (files) {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));

      await uploadMulti(formData)
        .then(
          (res) =>
            (listPaths = res.data.images.reduce(
              (prev, curr) => [...prev, curr.path],
              []
            ))
        )
        .catch(() => toast.error('Cannot upload images. Please try again!'));
    }

    return listPaths;
  };

  const handleReplaceContent = (content, textReplace) => {
    let startIndex = 0,
      index;

    const indexSrc = content.indexOf('src="data:') + 5;
    startIndex = indexSrc;
    index = content.indexOf(' alt="', startIndex);

    const text = content.substring(indexSrc, index - 1);

    return indexSrc < index ? content.replace(text, textReplace) : content;
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const dataPost = {
      ...itemSelected,
      title: titleUpdate,
      path: pathRef.current.value,
      content: content || '',
      order: Number(orderRef.current.value),
    };

    const images = handleRemoveFilesFromArray(listImages, listImagesIndex);

    const validator = modalUpdateValidator(dataPost);

    const arr = [];
    setMessages(arr);
    if (validator.error) {
      validator.error.details.forEach((item) =>
        arr.push({ key: item.context.key, message: item.message })
      );

      setMessages(arr);
      setIsLoading(false);
    } else {
      let checkPost = true;
      if (images && images?.length > 0) {
        await handleUploadImages(images)
          .then((res) => {
            let cont = content;
            res.forEach((item, index) => {
              cont = handleReplaceContent(cont, item);
            });
            dataPost.content = cont;
          })
          .catch((err) => {
            checkPost = false;
            setIsLoading(false);
            toast.error('Cannot upload images. Please try again!');
          });
      }
      if (checkPost)
        handlePost(dataPost, itemSelected ? 1 : 0)
          .then((res) => {
            setIsLoading(false);
            const newData = JSON.parse(JSON.stringify(data));
            if (itemSelected) {
              const index = newData.findIndex(
                (item) => item._id === itemSelected._id
              );
              newData[index] = dataPost;
            } else {
              dataPost._id = res.data.data._id;
              dataPost.id = res.data.data.id;
              dataPost.createdAt = res.data.data.createdAt;
              dataPost.updatedAt = res.data.data.updatedAt;
              newData.unshift(dataPost);
            }
            setData(newData);
            toast.success(res.data.message);
            setShowModal(false);
            listImages = [];
            listImagesIndex = [];
          })
          .catch((err) => {
            toast.error(err.response?.data?.message || err.message);
            setIsLoading(false);
          });
      else setIsLoading(false);
    }
  };

  const onImageUploadBefore = async (files, info, core, uploadHandler) => {
    // console.log(info);
    listImages = [...listImages, ...files];
  };

  const onImageUpload = (
    targetElement,
    index,
    state,
    info,
    remainingFilesCount,
    core
  ) => {
    if (state === 'delete' && info?.src?.indexOf('http') === -1) {
      listImagesIndex.push(index);
    }
  };

  const handleRemoveFilesFromArray = (listImages, listIndex) => {
    listIndex.forEach((item) => {
      listImages.splice(item, 1);
    });

    return listImages;
  };
  return (
    <>
      {showModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 outline-none focus:outline-none w-5/6 h-full'>
            <div className='relative w-auto my-6 mx-auto max-w-10/12 overflow-y-auto'>
              {/*content */}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-y-auto'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-2xl font-semibold'>{titleModal}</h3>
                  <button
                    className='p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:text-red-500'
                    onClick={() => setShowModal(false)}
                  >
                    <span className='bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      <i className='fa-regular fa-times'></i>
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='flex flex-wrap p-4 h-[500px] overflow-y-auto'>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Title <span className='text-red-500'>*</span>
                      </label>
                      <input
                        // ref={titleUpdateRef}
                        type='text'
                        placeholder='Title'
                        className='border-0 px-3 py-3 placeholder-slate-200 text-slate-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        value={titleUpdate}
                        onChange={(e) => setTitleUpdate(e.target.value)}
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'title' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Path <span className='text-red-500'>*</span>
                      </label>
                      <input
                        ref={pathRef}
                        type='text'
                        placeholder='Name customer'
                        className='border-0 px-3 py-3 placeholder-slate-200 text-slate-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        value={convertToPath(titleUpdate)}
                        disabled
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'path' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Order (Descending)
                      </label>
                      <input
                        ref={orderRef}
                        type='number'
                        placeholder='Order Number (Descending)'
                        className='border-0 px-3 py-3 placeholder-slate-200 text-slate-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        defaultValue={itemSelected?.order}
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'order' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>

                  <div className='w-full lg:w-full px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        content <span className='text-red-500'>*</span>
                        <small className='ml-4 text-red-500 text-xs normal-case font-medium'>
                          {messages.map((message) =>
                            message.key === 'content' ? message.message : null
                          )}
                        </small>
                      </label>
                      {(content !== undefined || content) && (
                        <SunEditor
                          autoFocus={false}
                          onImageUploadBefore={onImageUploadBefore}
                          onImageUpload={onImageUpload}
                          onChange={(content) => setContent(content)}
                          defaultValue={content}
                          setDefaultStyle={'height: 800px; font-size: 16px'}
                          setOptions={{
                            buttonList: [
                              [
                                'bold',
                                'underline',
                                'italic',
                                'strike',
                                'list',
                                'align',
                                'font',
                                'fontSize',
                                'formatBlock',
                                'fontColor',
                                'hiliteColor',
                                'textStyle',
                                'table',
                                'link',
                                'fullScreen',
                                'codeView',
                                'image',
                              ],
                            ],
                          }}
                        />
                      )}
                      {/* <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'content ' ? message.message : null
                        )}
                      </small> */}
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-slate-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          <Loading isLoading={isLoading} />
        </>
      ) : null}
    </>
  );
}
