import { Fragment, useEffect, useRef, useState } from 'react';
import { convertToPath } from '../../utils/formatString';
import {
  modalListImagesValidator,
  modalProductValidator,
} from '../../utils/validation';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { uploadMulti } from '../../services/file';
import Loading from '../Shared/Loading/Loading';
import { toast } from 'react-toastify';
import { search as searchCategories } from '../../services/category';
import { search as searchMaterials } from '../../services/material';
import { Combobox, Transition } from '@headlessui/react';

export default function Modal({
  titleModal,
  itemSelected,
  showModal,
  setShowModal,
  handlePost,
  setData,
  data,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const pathRef = useRef();
  const orderRef = useRef();

  const [nameProduct, setNameProduct] = useState('');
  const [category, setCategory] = useState({});
  const [material, setMaterial] = useState({});
  const [detail, setDetail] = useState();
  const [gallery, setGallery] = useState([]);
  const [description, setDescription] = useState();

  const [categories, setCategories] = useState('');
  const [materials, setMaterials] = useState('');
  const [messages, setMessages] = useState([]);
  const [listImages, setListImages] = useState([]);
  // const [indexImage, setIndexImage] = useState();
  // const [isDeleteImage, setIsDeleteImage] = useState(false);

  const [filteredCategories, setFilteredCategories] = useState();
  const [queryCategory, setQueryCategory] = useState('');

  const [filteredMaterials, setFilteredMaterials] = useState();
  const [queryMaterial, setQueryMaterial] = useState('');

  useEffect(() => {
    if (itemSelected && pathRef) {
      setNameProduct(itemSelected.name);
      pathRef.current.value = itemSelected.path;
      setCategory(itemSelected.category);
      setMaterial(itemSelected.material);
      setGallery(itemSelected.gallery);
      setDescription(itemSelected.description);
      setDetail(itemSelected.detail || '');
      orderRef.current.value = itemSelected.order || 0;
      // setListImages(itemSelected.listNameImages);
    } else emptyValues();
  }, [itemSelected]);

  const emptyValues = () => {
    if (pathRef) {
      setNameProduct('');
      pathRef.current.value = '';
      setCategory({});
      setMaterial({});
      setDetail('');
      setGallery([]);
      setDescription('');
      orderRef.current.value = 0;
    }
  };

  const handleUploadImages = async (files) => {
    let listPaths = [];
    if (files) {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));

      await uploadMulti(formData)
        .then((res) => {
          listPaths = res.data.images.reduce(
            (prev, curr) => [...prev, curr.path],
            []
          );
        })
        .catch(() =>
          toast.error(
            'Cannot upload images. Please try again with smaller images!'
          )
        );
    }

    return listPaths;
  };

  const handleSubmit = async (e) => {
    const uuid = window.sessionStorage.getItem('uuid');
    setIsLoading(true);
    const dataPost = {
      ...itemSelected,
      name: nameProduct,
      path: pathRef.current.value,
      category: category?._id,
      material: material?._id,
      detail,
      // gallery,
      description,
      updatedId: uuid,
      order: Number(orderRef.current.value),
    };

    if (!itemSelected) dataPost.createId = uuid;
    else
      dataPost.createdId = itemSelected?.createdId?._id || itemSelected?.createdId || '';

    const validator = modalProductValidator(dataPost);

    const arr = [];
    setMessages(arr);
    if (validator.error) {
      validator.error.details.forEach((item) =>
        arr.push({ key: item.context.key, message: item.message })
      );

      setMessages(arr);
      setIsLoading(false);
    } else {
      let checkPost = true;

      if (listImages && listImages?.length > 0) {
        await handleUploadImages(listImages).then((res) => {
          const images = res;
          const img = itemSelected ? itemSelected.gallery : [];
          dataPost.gallery = [...img, ...images];
        });
        // .catch((err) => {
        //   toast.error('Cannot upload images. Please try again!');
        //   setIsLoading(false);
        // });
        const validatorImages = modalListImagesValidator(dataPost);
        if (validatorImages.error) {
          validatorImages.error.details.forEach((item) =>
            arr.push({ key: item.context.key, message: item.message })
          );
          checkPost = false;
          setMessages(arr);
          setIsLoading(false);
        }
      } else
        dataPost.gallery = itemSelected
          ? gallery.filter((i) => typeof i === 'string')
          : [];

      if (checkPost)
        handlePost(dataPost, itemSelected ? 1 : 0)
          .then((res) => {
            dataPost.category = category;
            dataPost.material = material;
            const newData = JSON.parse(JSON.stringify(data));
            if (itemSelected) {
              const index = newData.findIndex(
                (item) => item._id === itemSelected._id
              );
              newData[index] = dataPost;
            } else {
              dataPost._id = res.data.data._id;
              dataPost.id = res.data.data.id;
              dataPost.createdAt = res.data?.data?.createdAt;
              dataPost.updatedAt = res.data?.data?.updatedAt;
              newData.unshift(dataPost);
            }
            setData(newData);
            toast.success(res.data.message);
            setShowModal(false);
            setListImages([]);
            setGallery(dataPost.gallery);
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      else {
        setIsLoading(false);
        toast.warning('You need upload a file!');
      }
    }
  };

  const handleInputChange = (key) => {
    const mess = JSON.parse(JSON.stringify(messages)).filter(
      (item) => item.key !== key
    );

    setMessages(mess);
  };

  // Get all categories
  useEffect(() => {
    searchCategories().then((res) => setCategories(res.data));
  }, []);

  // Get all material
  useEffect(() => {
    searchMaterials().then((res) => setMaterials(res.data));
  }, []);

  // filter categories
  useEffect(() => {
    if (categories) {
      const filtered =
        queryCategory === ''
          ? categories
          : categories.filter((item) => {
              return item.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(queryCategory.toLowerCase().replace(/\s+/g, ''));
            });

      setFilteredCategories(filtered);
    }
  }, [queryCategory, categories]);

  // filter materials
  useEffect(() => {
    if (materials) {
      const filtered =
        queryMaterial === ''
          ? materials
          : materials.filter((item) => {
              return item.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(queryMaterial.toLowerCase().replace(/\s+/g, ''));
            });

      setFilteredMaterials(filtered);
    }
  }, [queryMaterial, materials]);

  const handleChangeGallery = (e) => {
    const files = Array.from(e.target.files);
    setListImages([...listImages, ...files]);
    setGallery([...gallery, ...files]);
  };

  const handleDeleteGallery = (item, index) => {
    if (typeof item !== 'string') {
      // const list = JSON.parse(JSON.stringify(listImages));
      const index = listImages.findIndex(
        (l) => l.lastModified === item.lastModified
      );
      listImages.splice(index, 1);
      setListImages([...listImages]);
    }
    // const list = JSON.parse(JSON.stringify(gallery));
    gallery.splice(index, 1);
    setGallery([...gallery]);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 outline-none focus:outline-none w-5/6 h-full'>
            <div className='relative w-auto my-6 mx-auto max-w-10/12 overflow-y-auto'>
              {/*description*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-y-auto'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-2xl font-semibold'>{titleModal}</h3>
                  <button
                    className='p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none hover:text-red-500'
                    onClick={() => setShowModal(false)}
                  >
                    <span className='bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      <i className='fa-regular fa-times'></i>
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='flex flex-wrap p-4 h-[500px] overflow-y-auto'>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Name product <span className='text-red-500'>*</span>
                      </label>
                      <input
                        type='text'
                        placeholder='Name product'
                        className='border-0 px-3 py-3 placeholder-slate-200 text-slate-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        value={nameProduct}
                        onChange={(e) => setNameProduct(e.target.value)}
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'name' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Path <span className='text-red-500'>*</span>
                      </label>
                      <input
                        ref={pathRef}
                        type='text'
                        placeholder='Name product'
                        className='border-0 px-3 py-3 placeholder-slate-200 text-slate-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        value={convertToPath(nameProduct)}
                        disabled
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'path' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/3 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Order (Descending)
                      </label>
                      <input
                        ref={orderRef}
                        type='number'
                        placeholder='Order Number (Descending)'
                        className='border-0 px-3 py-3 placeholder-slate-200 text-slate-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        defaultValue={itemSelected?.order}
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'order' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-800 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Category <span className='text-red-500'>*</span>
                      </label>
                      <Combobox value={category} onChange={setCategory}>
                        <div className='relative w-full cursor-default rounded-lg bg-white text-left sm:text-sm'>
                          <Combobox.Input
                            id='category'
                            className={
                              'border-0 px-3 py-3 placeholder-slate-400 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 font-medium ' +
                              (messages.find(
                                (message) => message.key === 'category'
                              )
                                ? 'border-red-600'
                                : 'border-gray-300')
                            }
                            placeholder={'Category'}
                            displayValue={(item) => item?.name}
                            spellCheck={false}
                            onChange={(event) =>
                              setQueryCategory(event.target.value)
                            }
                          />
                          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-caret-down-fill'
                              viewBox='0 0 16 16'
                            >
                              <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                            </svg>
                          </Combobox.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                          afterLeave={() => setQueryCategory('')}
                        >
                          <Combobox.Options className='absolute mt-1 max-h-60 w-full md:w-1/2 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30'>
                            {filteredCategories &&
                            filteredCategories?.length === 0 &&
                            queryCategory !== '' ? (
                              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                                Nothing.
                              </div>
                            ) : (
                              filteredCategories?.map((item) => (
                                <Combobox.Option
                                  key={item._id}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? 'bg-teal-600 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={item}
                                  onClick={() => {
                                    handleInputChange('category');
                                  }}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {item.name}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                            active
                                              ? 'text-white'
                                              : 'text-teal-600'
                                          }`}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='16'
                                            height='16'
                                            fill='currentColor'
                                            className='bi bi-check2'
                                            viewBox='0 0 16 16'
                                          >
                                            <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                          </svg>
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </Combobox>
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'category' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-800 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Materials <span className='text-red-500'>*</span>
                      </label>
                      <Combobox value={material} onChange={setMaterial}>
                        <div className='relative w-full cursor-default rounded-lg bg-white text-left sm:text-sm'>
                          <Combobox.Input
                            id='sub-category'
                            className={
                              'border-0 px-3 py-3 placeholder-slate-400 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 font-medium ' +
                              (messages.find(
                                (message) => message.key === 'name'
                              )
                                ? 'border-red-600'
                                : 'border-gray-300')
                            }
                            placeholder={'Material'}
                            displayValue={(item) => item?.name}
                            spellCheck={false}
                            onChange={(event) =>
                              setQueryMaterial(event.target.value)
                            }
                          />
                          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-caret-down-fill'
                              viewBox='0 0 16 16'
                            >
                              <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                            </svg>
                          </Combobox.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                          afterLeave={() => setQueryMaterial('')}
                        >
                          <Combobox.Options className='absolute mt-1 max-h-60 w-full md:w-1/2 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30'>
                            {filteredMaterials &&
                            filteredMaterials?.length === 0 &&
                            queryMaterial !== '' ? (
                              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                                Nothing.
                              </div>
                            ) : (
                              filteredMaterials?.map((item) => (
                                <Combobox.Option
                                  key={item._id}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? 'bg-teal-600 text-white'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={item}
                                  onClick={() => {
                                    handleInputChange('material');
                                  }}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {item.name}
                                      </span>
                                      {selected ? (
                                        <span
                                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                            active
                                              ? 'text-white'
                                              : 'text-teal-600'
                                          }`}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='16'
                                            height='16'
                                            fill='currentColor'
                                            className='bi bi-check2'
                                            viewBox='0 0 16 16'
                                          >
                                            <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                          </svg>
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </Combobox>
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'material' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>

                  <div className='w-full lg:w-1/2 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-800 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Gallery (can select numerous){' '}
                        <span className='text-red-500'>*</span>
                      </label>
                      <input
                        type='file'
                        className='block w-full text-sm text-slate-800 font-semibold
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-red-50 file:text-red-700
                            hover:file:bg-red-100
                          '
                        accept='image/png, image/jpeg'
                        multiple={true}
                        onChange={handleChangeGallery}
                      />
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'gallery' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                  <div className='w-full lg:w-1/2 px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-800 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Gallery
                      </label>
                      <div className='flex items-center gap-1 flex-wrap'>
                        {gallery &&
                          gallery?.length > 0 &&
                          gallery?.map((item, index) => (
                            <div key={index} className='relative group'>
                              <img
                                src={
                                  typeof item === 'string'
                                    ? item
                                    : URL.createObjectURL(item)
                                }
                                className={
                                  'rounded-full w-[50px] h-[50px] object-cover border-2 relative group-hover:opacity-20 ' +
                                  (index % 2 === 0
                                    ? 'border-red-500'
                                    : 'border-violet-500')
                                }
                                alt=''
                              />
                              <div
                                className='group-hover:flex hidden absolute inset-0 rounded-full justify-center items-center cursor-pointer text-lg font-medium'
                                title='Delete'
                                onClick={() => handleDeleteGallery(item, index)}
                              >
                                x
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className='w-full lg:w-full px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Detail
                      </label>
                      {(detail !== undefined || detail) && (
                        <SunEditor
                          autoFocus={false}
                          // onImageUploadBefore={onImageUploadBefore}
                          // onImageUpload={onImageUpload}
                          onChange={(detail) => setDetail(detail)}
                          defaultValue={detail}
                          setDefaultStyle={'height: 100px; font-size: 16px'}
                          setOptions={{
                            buttonList: [
                              [
                                'bold',
                                'underline',
                                'italic',
                                'strike',
                                'list',
                                'align',
                                'font',
                                'fontSize',
                                'formatBlock',
                                'fontColor',
                                'hiliteColor',
                                'textStyle',
                                'table',
                                'link',
                                'fullScreen',
                                'codeView',
                              ],
                            ],
                          }}
                        />
                      )}
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'detail' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>

                  <div className='w-full lg:w-full px-4'>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-slate-500 text-xs font-bold mb-2'
                        htmlFor='grid-password'
                      >
                        Product Specification
                      </label>
                      {(description !== undefined || description) && (
                        <SunEditor
                          autoFocus={false}
                          // onImageUploadBefore={onImageUploadBefore}
                          // onImageUpload={onImageUpload}
                          onChange={(description) =>
                            setDescription(description)
                          }
                          defaultValue={description}
                          setDefaultStyle={'height: 250px; font-size: 16px'}
                          setOptions={{
                            buttonList: [
                              [
                                'bold',
                                'underline',
                                'italic',
                                'strike',
                                'list',
                                'align',
                                'font',
                                'fontSize',
                                'formatBlock',
                                'fontColor',
                                'hiliteColor',
                                'textStyle',
                                'table',
                                'link',
                                'fullScreen',
                                'codeView',
                              ],
                            ],
                          }}
                        />
                      )}
                      <small className='text-red-500 font-medium'>
                        {messages.map((message) =>
                          message.key === 'description' ? message.message : null
                        )}
                      </small>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-slate-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          <Loading isLoading={isLoading} />
        </>
      ) : null}
    </>
  );
}
