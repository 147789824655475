import Joi from 'joi';

export function loginValidator(data) {
  const rule = Joi.object({
    username: Joi.string().min(1).max(24).required(),
    password: Joi.string().min(1).max(24).required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function registerValidator(data) {
  const rule = Joi.object({
    username: Joi.string().max(24).required(),
    email: Joi.string()
      .max(255)
      .email({ tlds: { allow: false } })
      .required(),
    password: Joi.string()
      .pattern(new RegExp('^[a-zA-Z0-9]{6,20}$'))
      .required(),
  }).messages({
    'string.empty': `Your {#label} is required`,
    'string.pattern.base':
      'Your {#label} length must be greater than 6 characters. Password cannot contain a special character.',
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalCustomerValidator(data) {
  const rule = Joi.object({
    ...data,
    thumbnail: Joi.string().required(),
    order: Joi.number().required(),
    name: Joi.string().max(100).required(),
    path: Joi.string().max(100).required(),
  }).messages({
    'string.empty': `Your {#label} is required`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalUserValidator(data) {
  const rule = Joi.object({
    ...data,
    username: Joi.string().min(1).max(24).required(),
    email: Joi.string().email({ tlds: { allow: false } }),
    firstName: Joi.string().max(24),
    lastName: Joi.string().max(24),
    avatar: Joi.string(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalProductValidator(data) {
  const rule = Joi.object({
    ...data,
    name: Joi.string().max(100).required(),
    path: Joi.string().max(100).required(),
    category: Joi.string().required(),
    material: Joi.string().required(),
    // description: Joi.string().required(),
    gallery: Joi.array().optional().empty(Joi.array().length(0)),
    order: Joi.number(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalImageValidator(data) {
  const rule = Joi.object({
    ...data,
    thumbnail: Joi.string().required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalListImagesValidator(data) {
  const rule = Joi.object({
    ...data,
    gallery: Joi.array().min(1),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalMaterialValidator(data) {
  const rule = Joi.object({
    ...data,
    thumbnail: Joi.string().required(),
    order: Joi.number().required(),
    name: Joi.string().max(100).required(),
    path: Joi.string().max(100).required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalCategoryValidator(data) {
  const rule = Joi.object({
    ...data,
    thumbnail: Joi.string().required(),
    order: Joi.number().required(),
    name: Joi.string().max(100).required(),
    path: Joi.string().max(100).required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalUpdateValidator(data) {
  const rule = Joi.object({
    ...data,
    title: Joi.string().max(100).required(),
    order: Joi.number().required(),
    path: Joi.string().max(100).required(),
    content: Joi.string().required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function modalSlideValidator(data) {
  const rule = Joi.object({
    ...data,
    // title: Joi.string().required(),
    order: Joi.number().required(),
    backgroundImage: Joi.string().required(),
    redirectTo: Joi.string().max(100).required(),
    contentLink: Joi.string().max(100).required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
  });

  return rule.validate(data, { abortEarly: false });
}
